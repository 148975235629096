var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal"
  }, [_c('wrapper', {
    scopedSlots: _vm._u([{
      key: "alert",
      fn: function () {
        return [_vm._v(" Выбранный способ оплаты недоступен ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Ведутся технические работы по активации платежной системы. ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('p', [_vm._v(" Это не должно занять много времени, онлайн оплата должна заработать через 2-3 часа. Безналичный расчет для юридических лиц уже работает в штатном режиме! ")]), _c('p', {
          staticClass: "mt16"
        }, [_vm._v("Спасибо за понимание!")])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };