/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalPaymentMethodUnavailable.vue?vue&type=template&id=b6819bbc&scoped=true"
import script from "./ModalPaymentMethodUnavailable.vue?vue&type=script&lang=js"
export * from "./ModalPaymentMethodUnavailable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6819bbc",
  null
  
)

export default component.exports